&-summary {
    margin: 30px 0;
    padding: 30px;

    background: $gray-light;

    &__thanks {
        display: flex;

        margin-bottom: 30px;

        align-items: center;

        svg {
            margin-right: 20px;
        }
    }

    &__row {
        display: flex;
        flex-direction: row;

        padding-top: 25px;
        padding-right: 10px;
        padding-bottom: 25px;
        padding-left: 10px;

        border-top: 1px #bbb solid;

        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__name {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(xs) {
            width: 100%;
        }

        &::after {
            display: inline-block;
            white-space: nowrap;

            width: 40px;
            height: 40px;
            margin-right: 5px;
            margin-left: 0;

            content: ' ';
            vertical-align: text-bottom;

            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='102.4' width='102.4'%3E%3Cpath transform='scale(0.1,-0.1) translate(0,-960)' fill='%230368db' d='M707.048 500.825l-44.229 44.698-151.017-154.006-151.017 154.006-43.831-44.698 194.848-199.111z'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 40px;

            margin-inline-start: 0;
            margin-inline-end: 5px;
        }
    }

    &__status {
        display: flex;
        flex-direction: row-reverse;

        width: calculateRem(240px);

        @include media-breakpoint-down(xs) {
            margin-top: calculateRem(5px);
            width: 100%;
        }

        align-items: center;
        justify-content: flex-end;
    }

    &__symbol {
        display: inline-block;

        width: 40px;
        height: 36px;
        margin-right: 20px;

        color: #262626;
        border-color: #fff;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 32px 32px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2),
            0 1px 2px 0 rgba(0, 0, 0, 0.1);

        &.-given {
            background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="64" height="50" viewBox="0 0 64 50"%3E%3Cpath fill="%230368db" d="M25.418 50L0 24.674l7.572-7.545 17.422 17.356L56.024 0 64 7.123z"/%3E%3C/svg%3E');
            background-size: 14px 14px;
        }

        &.-revoked {
            background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" height="102.4" width="102.4"%3E%3Cpath transform="scale(0.1,-0.1) translate(0,-960)" fill="%23e0e0e0" d="M634.88 611.84l-122.88-122.88-122.88 122.88-40.96-40.96 122.88-122.88-122.88-122.88 40.96-40.96 122.88 122.88 122.88-122.88 40.96 40.96-122.88 122.88 122.88 122.88z"/%3E%3C/svg%3E');
        }
    }
}

&-cookies {
    &__row {
        display: grid;

        margin-bottom: 25px;

        grid-template-areas: 'entry description';
        grid-template-columns: 25% 75%;
        grid-gap: 8px;
    }
}

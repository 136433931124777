/*-----------------------------------*/
/* Global Variable Declarations.
/*-----------------------------------*/
$css-prefix: 'bmw-eprivacy';

// Colours.
$white: #fff;
$black: #000;
$gray-dark: #262626 !default;
$gray-light: #e6e6e6 !default;
$gray-lighter: #f2f2f2 !default;
$bmw-primary: #1d6ad5 !default;
$bmw-secondary: #0653b6;

// Typography.
$font-size-root: 16px !default;
$font-size-base: 1rem !default;

// Transformations.
$micro-transition-time: 150ms;
$quick-transition-time: 250ms;
$default-transition-time: 400ms;
$transition-curve: ease-in-out;

@import './utils/variables.scss';
@import './utils/functions.scss';

html {
    box-sizing: border-box;

    -ms-overflow-style: scrollbar;

    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }
}

.#{$css-prefix} {
    @import './utils/bootstrap.scss';
    @import './components/intro.scss';
    @import './components/accordion.scss';
    @import './components/body.scss';
    @import './components/switch.scss';
    @import './components/category.scss';
    @import './components/section.scss';
    @import './components/cookies.scss';
    @import './components/summary.scss';
    @import './components/cookie-banner.scss';
}

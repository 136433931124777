/*
 *  This is a modified copy of '../../node_modules/bootstrap/scss/bootstrap-grid';
 */

/*!
 * Bootstrap Grid v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@at-root {
    @-ms-viewport {
        width: device-width;
    } // stylelint-disable-line at-rule-no-vendor-prefix
}

@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';

@import '../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../node_modules/bootstrap/scss/mixins/grid-framework';
@import '../../node_modules/bootstrap/scss/mixins/grid';

// .#{cssPrefix}
// {
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/utilities/display';
@import '../../node_modules/bootstrap/scss/utilities/flex';
@import '../../node_modules/bootstrap/scss/utilities/spacing';
// }

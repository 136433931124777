// ----------------------------------------------- //
// Cookie Notification Styles
// ----------------------------------------------- //
.bmw-eprivacy-banner {
    position: fixed;
    z-index: 999999999999;
    bottom: 0;
    left: 0;
    right: 0;

    border-top: 1px solid $gray-light;
    background: $white;
    box-sizing: border-box;

    &__container {
        margin: 0 auto;
        padding: 30px 0 40px;

        text-align: left;

        h2,
        h3,
        h4 {
            margin: 0 0 calculateRem(15px);
        }

        a {
            display: block;

            margin: calculateRem(20px) 0;

            text-decoration: underline;
        }
    }

    &__ctas {
        button {
            width: 100%;

            &:not(:first-child) {
                margin-top: 10px;
            }

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        @include media-breakpoint-up(md) {
            align-items: stretch;
            display: flex;

            button {
                width: auto;

                &:not(:first-child) {
                    margin-top: 0;
                    margin-left: 10px;
                }

                &:not(:last-child) {
                    margin-bottom: 0;
                    margin-right: 10px;
                }
            }
        }
    }
}

&-accordion {
    @include media-breakpoint-up(md) {
        padding-left: 50px;
    }

    &__body {
        display: none;

        width: 100%;
        padding-bottom: calculateRem(20px);

        .-expanded > & {
            display: block;
        }
    }

    &__header {
        position: relative;

        width: 100%;
        padding: 20px 0 20px 40px;

        cursor: pointer;

        p {
            margin: 0;
        }

        &::before,
        &::after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;

            width: 15px;
            height: 2px;
            margin: auto;

            content: '';
            transition: transform ease-in-out $micro-transition-time;
            transform: translateY(-50%);

            border-radius: 100px;
            background: currentColor;
        }

        &::before {
            transform: rotate(45deg);

            .-expanded > & {
                transform: rotate(-45deg);
            }
        }

        &::after {
            left: 9.1px;

            transform: rotate(-45deg);

            .-expanded > & {
                transform: rotate(45deg);
            }
        }

        h2 {
            margin: 0;
            padding: calculateRem(15px) 0;
        }
    }

    &__item {
        display: flex;

        margin-bottom: calculateRem(15px);
        padding: calculateRem(15px) 0 calculateRem(20px);

        border-bottom: 1px solid $gray-light;

        &:nth-last-child(1) {
            border-bottom: none;
        }

        &-left {
            flex: 1 1 auto;
        }
        &-right {
            text-align: right;
            white-space: nowrap;

            flex: 1 1 25%;
        }
    }
}

&-section {
    position: relative;

    padding-top: 30px;

    border-top: 1px solid $gray-light;

    &.-category {
        padding: 30px 0;

        min-width: calculateRem(359px);

        @include media-breakpoint-down(xs) {
            h2 {
                max-width: calculateRem(319px);
            }
        }
    }

    h2 {
        margin-bottom: 20px;
    }

    ul {
        margin-top: 20px;
        margin-left: 0;
        padding-left: 0;

        list-style-type: none;
    }
    li {
        display: flex;

        margin-bottom: 7px;

        &:before {
            display: flex;

            width: 36px;
            height: 22px;

            content: ' ';

            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='64' height='50' viewBox='0 0 64 50'%3E%3Cpath fill='%230368db' d='M25.418 50L0 24.674l7.572-7.545 17.422 17.356L56.024 0 64 7.123z'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 14px 14px;

            flex: 0 0 36px;
        }
    }
}

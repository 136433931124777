&-switch {
    position: absolute;
    top: 30px;
    right: 0;

    display: inline-block;

    width: 65px;
    height: 30px;

    cursor: pointer;

    &.-on {
        width: 100px;
        top: calculateRem(56px);
        text-align: right;
    }

    input {
        width: 0;
        height: 0;

        opacity: 0;
    }
}
&-slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;

    cursor: pointer;
    transition: 0.4s;

    background-color: $gray-light;

    align-items: center;

    &:before {
        position: absolute;
        top: 0;
        left: 0;

        width: 36px;
        height: 30px;

        content: '';
        transition: 0.4s;

        background-color: $gray-dark;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='102.4' width='102.4'%3E%3Cpath transform='scale(0.1,-0.1) translate(0,-960)' fill='%23e0e0e0' d='M634.88 611.84l-122.88-122.88-122.88 122.88-40.96-40.96 122.88-122.88-122.88-122.88 40.96-40.96 122.88 122.88 122.88-122.88 40.96 40.96-122.88 122.88 122.88 122.88z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 32px 32px;
        box-shadow: 0 2px 5px 0 rgba($black, 0.2), 0 1px 2px 0 rgba($black, 0.1);

        input:checked + & {
            transform: translateX(29px);

            background-color: $white;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='64' height='50' viewBox='0 0 64 50'%3E%3Cpath fill='%230368db' d='M25.418 50L0 24.674l7.572-7.545 17.422 17.356L56.024 0 64 7.123z'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 14px 14px;
        }
    }
}
